<template>
  <div class="doc">
    <RoomNav name="RoomNav"></RoomNav>
    <div style="width: 1500px;margin:0 auto;overflow: hidden;margin-top:105px;">
      <div style="width:894px;height: auto;float: left;margin: 0 0 0 294px;background: #FFFFFF; box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 1px;padding: 24px 30px 10px;border-radius:5px;box-sizing: border-box;">
        <!-- 标题 -->
        <div class="title">{{doc.title}}</div>

        <!-- 内容 -->
        <div>
          <div class="content" v-html="doc.content" style="margin-bottom: 20px; max-width: 100%; overflow-wrap: break-word"></div>
        </div>


        <div class="file-box" v-for="item in doc.enclosure" :key="item.id">
          <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle;">
          <span  style="font-size: 18px; vertical-align: middle;">{{ item.name }}</span>
          <span class="hand op" style="float: right;color: red" @click="docDownload(item)">下载</span>
        </div>

      </div>

      <div class="page-right" style="width:302px;float: right;">
        <div style="width:100%;height:auto;">
          <!-- room信息 -->
          <div style="background:#FFF;padding:15px;margin-bottom: 10px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
            <div style="color: #444444;font-size: 16px; margin-bottom: 20px" v-if="doc.roomName != null">
              <div style="font-weight: 600; font-size: 16px; margin-bottom: 8px">事件所属:</div>
              <div class="contentTypes">
                <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                <span>{{doc.roomName}}</span>
              </div>
            </div>
            <div style="color: #444444;font-size: 14px;">
              <span style="font-weight: 600; font-size: 16px">事件创建人:</span>
              <div style="margin: 0 0 20px 0">
                <User :data="doc.user"></User>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!--支付弹窗-->
<!--      <el-dialog :visible.sync="showPay" width="500px" class="event-form">-->
<!--          <el-container>-->
<!--              <el-main>-->
<!--                  <div style="width: 400px;margin-left: 40px;margin-top: 10px;">-->
<!--                      <AaDaPayCode type="2"  payType="8" :orderNo="orderNo" :accountType="1" :memberId="doc.individMemberId" :payAmt="doc.money+''" :uuid="doc.uuid"   ref="child"></AaDaPayCode>-->
<!--                  </div>-->
<!--              </el-main>-->
<!--          </el-container>-->
<!--      </el-dialog>-->
  </div>
</template>

<script>
import RoomNav from "./components/RoomNav";
import User from "@/components/common/User.vue";
import AaDaPayCode from '@/components/common/Pay/AaDaPayCode'

export default {
  name: "docDetail",
  components: {User, RoomNav,AaDaPayCode},
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  data() {
    return {
        doc:{},
    }
  },

    created() {
        var that = this;
        //预支付订单监听
        this.$EventBus.$EventBus.$off("prePayOrder");
        this.$EventBus.$on("prePayOrder", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    that.bankPay(data.toPay);
                }
            });
        });
    },

  mounted() {
      var that = this;
      this.getDocDetail();

      //监听支付回调广播
      this.$EventBus.$EventBus.$off("payMsg");
      this.$EventBus.$on("payMsg", (data) => {
          this.$nextTick(() => {
              if (data.code == 100 ) {
                  that.getDocDetail();
              }
          });
      });
  },

  methods: {
    // 获取room信息
    getDocDetail() {
      this.newApi.getDocDetail({
        id: this.$route.params.id
      }).then(ret => {
        this.doc = ret.data;
      })
    },

    docDownload(item){
      var that = this;
      if (!this.utils.toAuth(1)) {
        return false;
      }
        if(this.doc.payStatus == 0 && this.user.uuid != this.doc.uuid){
          that.utils.confirm("付费文档需要支付,立即支付吗？",function(){
            that.newApi.downloadDoc({docId:that.doc.id}).then((res) => {
              if(res.isSuccess == 1){
                // <AaDaPayCode type="2"  payType="8" :orderNo="orderNo" :accountType="1" :memberId="doc.individMemberId" :payAmt="doc.money+''" :uuid="doc.uuid"   ref="child"></AaDaPayCode>-->
                let params = that.$store.state.payParams;
                params.type = 2;
                params.payType = 8;
                params.orderNo = res.data;
                params.payAmt = that.utils.keepTwoDecimalFull(that.doc.money);
                params.collectionUuid = that.doc.uuid;
                params.memberId = that.doc.memberId;
                that.sign = that.utils.random_string(6);
                that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                  that.b('/newAdaPay/?sign='+that.sign);
                })
              }
            })
          })
        }else{
          that.utils.download(item.url.url,item.url.name);
        }
    },

      //银行卡确认支付
      bankPay(data){
          var that = this;

          if(data.activeName != 'corporate'){
              var params = {};
              params.orderNo = data.orderNo;
              params.code = data.code;
              params.payPwd = data.payPwd;

              that.newApi.settleAccountTransferDetermine(params).then( () =>{})
          }
      },
  }
}
</script>

<style scoped>
.doc {
  overflow:hidden;
  background-color: #f0f0f0;
  min-width: 1500px;
  min-height: 100vh;
}
.title{
  font-size: 23px;
  color: #333;
  height: 60px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.doc .content {

}

.file-box{
  padding: 8px 10px;
  margin-bottom: 4px;
  background-color: #f1f1f1;
  overflow: hidden;
}

.doc .content >>> img {
  max-width: 100%;
  max-height: 100%;
}
</style>
